import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
      <h2>
          Privacy Policy
      </h2>
      <p>

          26area.com is committed to protecting the privacy of our users. This privacy policy applies to the information we collect when you use our website and services.

          Information Collection and Use

          We collect personal information from you when you register for an account or use certain features of our service. This information may include your name, email address, and other contact information.

          We use this information to provide you with the services you request and to improve the overall quality of our service. We may also use this information for internal research and analysis, and to send you promotional materials.

          Information Sharing and Disclosure

          We do not share or disclose your personal information to third parties without your consent, except as required by law or as necessary to provide you with the services you request.

          We may share your personal information with service providers who work on our behalf to provide you with the services you request. These service providers are contractually bound to protect the privacy of your personal information and are prohibited from using it for any other purpose.

          Security

          We take the security of your personal information seriously and take reasonable measures to protect it from unauthorized access, use, or disclosure.

          Changes to This Privacy Policy

          We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on our website.

          Contact Us

          If you have any questions about this privacy policy or our handling of your personal information or deletion, please contact us at 26area.com@gmail.com"
      </p>
  </>

);